import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import MinHeightTextarea from "./StyledTextArea";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {
  addDoc,
  collection,
  getDoc,
  doc,
  query,
  where,
  getDocs,
  setDoc,
} from "@firebase/firestore";
import { firestore } from "./firebase";
import { StyledModal, StyledBackdrop, style } from "./StyledModal";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import BasicAccordion from "./Questions";
import "react-toastify/dist/ReactToastify.min.css";
import axios from "axios";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";
ReactGA.initialize([
  {
    trackingId: "G-E8S97JF89L",
  },
]);

function thankYouPage(productId, office) {
  return `
<?php
$method = $_SERVER['REQUEST_METHOD'];
$sitename = "SITENAME";
$name = trim(strip_tags($_POST["name"]));
$phone = trim(strip_tags($_POST["phone"]));
$comment = trim(strip_tags($_POST["comment"]));

$crm = trim(strip_tags($_POST["crm"]));
$price = trim(strip_tags($_POST["price"]));
$title = trim(strip_tags($_POST["title"]));


if(!$name){
    exit();
}

$texttg = urlencode("Інформація про покупця:\\n\\n" . $sitename . "\\n\\nПІП: " . $name . "\\nТелефон: " . $phone . "\\n\\nЗапит надійшов з сайту: " . $_SERVER['HTTP_REFERER']);

$botToken = '5647573466:AAFXJ7KnyRQSt_rgTtdmEI0weW9p35G7q0Q';
$chatId = 'CHATID';

$dot = $title ? "\\n Доп: $title" : '';

$sendToTelegram = fopen("https://api.telegram.org/bot{$botToken}/sendMessage?chat_id={$chatId}&parse_mode=html&text={$texttg}{$dot}","r");

session_start();

$products_list_dop = array(
    0 => array(
        'product_id' => '${productId}',
        'price' => 'PRICE',
        'count' =>  1
    )
);
$order_id = number_format(round(microtime(true) * 10) , 0, '.', '');


$products_dop = urlencode(serialize($products_list_dop));
$sender_dop = urlencode(serialize($_SERVER));

$data_dop = array(
    'key' => '11847da680a601c43768ec1e652b6b05',
    'order_id' => $order_id ,
    'country' => 'UA',
    'office' => '${office}',
    'products' => $products_dop,
    'bayer_name' => $_REQUEST['name'].'---'.$order_id,
    'phone' => $_REQUEST['phone'],
    'email' => $_REQUEST['email'],
    'comment' => $comment,
    'delivery' => $_REQUEST['delivery'],
    'delivery_adress' => $_REQUEST['delivery_adress'],
    'payment' => '',
    'sender' => $sender_dop,
    'utm_source' => $_SESSION['utms']['utm_source'],
    'utm_medium' => $_SESSION['utms']['utm_medium'],
    'utm_term' => $_SESSION['utms']['utm_term'],
    'utm_content' => $_SESSION['utms']['utm_content'],
    'utm_campaign' => $_SESSION['utms']['utm_campaign'],
    'additional_1' => '',
    'additional_2' => '',
    'additional_3' => '',
    'additional_4' => ''
);

$curl_dop = curl_init();
curl_setopt($curl_dop, CURLOPT_URL, 'http://unique.lp-crm.biz/api/addNewOrder.html');
curl_setopt($curl_dop, CURLOPT_POST, true);
curl_setopt($curl_dop, CURLOPT_RETURNTRANSFER, true);
curl_setopt($curl_dop, CURLOPT_POSTFIELDS, $data_dop);
$out_dop = curl_exec($curl_dop);

curl_close($curl_dop);

if($title) {
    exit();
}
?>

<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    <title>Дякуємо за заявку!</title>
    <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&amp;subset=cyrillic,cyrillic-ext" rel="stylesheet">
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.0/css/bootstrap.min.css" integrity="sha384-9gVQ4dYFwwWSjIDZnLEWnxCjeSWFphJiwGPXr1jddIhOegiu1FwO5qRGvFXOdJZ4" crossorigin="anonymous">
    <script src="https://code.jquery.com/jquery-1.11.0.min.js"></script>
    <script src="https://code.jquery.com/jquery-migrate-1.2.1.min.js"></script>
    
    <link href="thankyou.css" rel="stylesheet">

</head>
<body>

<style>
    body {
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        font-weight: 300;
    }
    .thank-please {
        font-size: 20px;
        color: #5b609a;
        font-weight: 700;
        display: block;
    }
    .info {
        border-top: 1px solid #dedede;
        margin: 10px 0;
    }
    .info span {
        font-size: 20px;
        font-weight: 700;
    }
    .product {
        border: 1px solid #ececec;
        /* padding: 10px; */
        margin-bottom: 30px;
    }

    a.button {
        display: block;
        background-color: green;
        color: #fff;
        width: 100%;
        padding: 10px 0;
        text-decoration: none;
        transition: all .3s ease;
        font-weight: 700;
        margin-top: 20px;
    }
    a.button:hover {
        text-decoration: none;
        background-color: rgb(5, 180, 5);
    }
    .old-price {
        font-size: 22px;
        line-height: 22px;
        text-decoration: line-through;
    }
    .new-price {
        font-size: 28px;
        line-height: 28px;
        color: green;
        font-weight: 700;
    }
    .product p {
        padding: 0;
        margin: 5px;
    }

    .product-title {
        padding: 10px;
    }
</style>

    <div class="container">
        <div class="row text-center">
        <div class="col-12">
            <h1>Вітаємо <span class="thank_name"><? echo $name; ?></span>, ваше замовлення прийнято!</h1>
            <p>Найближчим часом з вами зв'яжеться оператор для підтвердження замовлення.</p>
            <span class="thank-please">Прохання, тримайте увімкненим ваш телефон!</span>
            <div class="info" style="margin-top:10px;padding-top:20px;">
                <p>Будь ласка, перевірте правильність введеної Вами інформації!</p>
                <span>Ім'я:</span> <span id="user_name"><? echo $name; ?></span><br>
                <span>Телефон: </span> <span id="user_phone"><? echo $phone; ?></span>
                <p>Якщо Ви помилилися під час заповнення форми, можете <a href="/">заповнити заявку ще раз.</a></p>
            </div>
        </div>
        </div>
        <!--dophere-->
    </div>
    
<script>
$(function(){
    $('a.add-to-order').on('click',function(e){
    var crm = $(this).attr('data-crm');
    var name = $('#user_name').text();
    var phone = $('#user_phone').text();
    e.preventDefault();
    $.ajax({
        type:"POST",
        url:"thank-you.php",
        data: {
        crm: $(this).attr('data-crm'),
        price: $(this).attr('data-price'),
        title: $(this).attr('data-title'),
        name: name,
        phone: phone,
        product_name: "upsell",
        pagename: "upsell"
        },
        success:function(text){
        
            $('a[data-crm="'+ crm +'"]').css('display', 'none');
            $('a[data-crm="'+ crm +'"]').next().css('display', 'block');
        
        }
    });
    });
});
</script>
<script>
$(document).ready(function(){
    $('.more_info').click(function(){
            $(this).next().slideToggle("slow");
    });
});
</script>

</body>
</html>
    `;
}

export const sendTelegramMessage = async (data, taskId, t) => {
  const token = process.env.REACT_APP_botToken;
  const chat_id = process.env.REACT_APP_chatId;
  const chatIdLocal = process.env.REACT_APP_chatIdLocal;

  const url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chat_id}&text=${data}&parse_mode=html`;
  if (taskId) {
    const localMessage = `${t} - https://easy-land.site/task.php?id=${taskId}`;

    fetch(
      `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatIdLocal}&text=${localMessage}&parse_mode=html`
    )
      .then((response) => response.json())
      .then((result) => {})
      .catch((error) => console.log("error", error));
  }

  fetch(url)
    .then((response) => response.json())
    .then((result) => {})
    .catch((error) => console.log("error", error));
};

export const addThankYou = async (
  id,
  productId,
  office,
  fileData,
  fileName
) => {
  let myHeaders = new Headers();
  myHeaders.append("Authorization", process.env.REACT_APP_clickUpApiKey);
  const text = thankYouPage(productId, office);

  const file = new Blob([fileData || text], { type: "text/plain" });
  const formData = new FormData();
  formData.append("attachment", file, fileName || "thank-you.php");

  const response = await fetch(
    `https://api.clickup.com/api/v2/task/${id}/attachment`,
    {
      method: "POST",
      body: formData,
      headers: myHeaders,
    }
  );
};

export default function NewOrder({ usd }) {
  const [open, setOpen] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [clickUpTaskId, setClickUpTaskId] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setClickUpTaskId(null);
  };

  async function getIP() {
    const response = await fetch("https://api.ipify.org/?format=json");
    const data = await response.json();
    return data?.ip;
  }

  const createDomain = async (domain, subdomain) => {
    const r = await axios.post(
      "https://easy-land.site/api.php",
      {
        domain,
        subdomain,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return r?.data;
  };

  const getUserByTelegram = async (telegram) => {
    let id = null;
    const q = query(
      collection(firestore, "order_users"),
      where("telegram", "==", telegram.toLowerCase())
    );

    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      console.log("empty");
    } else {
      querySnapshot.forEach((d) => {
        id = d.id;
      });
    }
    return id;
  };

  const checkIfUserExists = async (telegram) => {
    const q = query(
      collection(firestore, "order_users"),
      where("telegram", "==", telegram.toLowerCase())
    );

    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  const createNewUser = async (data) => {
    const orderUsersRef = collection(firestore, "order_users");
    const createdAt = new Date().toISOString();

    let d = {
      name: data?.name,
      telegram: data?.telegram,
      ip: data?.ip,
      createdAt,
    };

    try {
      return addDoc(orderUsersRef, d);
    } catch (err) {
      console.log(err);
    }
  };

  const getTechnology = async (site) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    return fetch(
      `https://whatcms.org/API/Tech?key=wjo7icnd23j9tl5potk6gk5i2odulkjko6szq20mj6upfaikxawmh26tjwo8crsof2wd50&url=${site}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result?.results);
        console.log(result?.results?.[0]?.name);
        return result?.results?.[0]?.name || null;
      })
      .catch((error) => "error");
  };

  const createNewTask = async (data, createDomainData) => {
    const {
      userId,
      name,
      originaTelegram,
      telegram,
      site,
      domain,
      productId,
      officeId,
      comment,
      createdAt,
      subdomain,
      // ip,
    } = data;

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", process.env.REACT_APP_clickUpApiKey);
    const tUserId = localStorage.getItem("telegramId");

    // var myHeadersss = new Headers();
    // myHeaders.append("Content-Type", "application/json");

    // var raws = JSON.stringify({
    //   telegramId: tUserId,
    //   site,
    //   domain,
    //   subdomain,
    //   productId,
    //   officeId,
    // });

    // const orderId = (
    //   await axios.post("https://easy-land.site/newOrder.php", raws)
    // ).data;
    const raw = JSON.stringify({
      name: site,
      description: `
      http://localhost:3000?&site=${site}&domain=${domain.toLowerCase()}&subdomain=${subdomain.toLowerCase()}&productId=${productId}&officeId=${officeId}
      Ім'я: ${name}
      Телеграм: ${originaTelegram}
      Домен: ${domain.toLowerCase()}
      Subdomain: ${subdomain.toLowerCase()}
      Товар: ${productId}
      Відділення: ${officeId}
      Коментар: ${comment}
      Дата: ${moment(createdAt).format("DD.MM.YYYY HH:mm")}
      Інфа по юкрейну: ${JSON.stringify(createDomainData)}
      `,
      status: "New",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    var requestTagOptions = {
      method: "POST",
      headers: myHeaders,
    };

    fetch(
      "https://api.clickup.com/api/v2/list/900200949105/task",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        const { id } = result;
        setClickUpTaskId(id);
        const taskUrl = `https://app.clickup.com/t/${id}`;
        const message = `Заявка додана успішно! ${taskUrl}`;
        addThankYou(id, productId, officeId);

        ReactGA.event({
          category: "SITE",
          action: "NEW SITE REQUEST",
          value: 10, // optional, must be a number
        });

        getTechnology(site).then((r) => {
          sendTelegramMessage(message, id, r);

          if (r) {
            fetch(
              `https://api.clickup.com/api/v2/task/${id}/tag/${r}`,
              requestTagOptions
            );
          }
        });
      })
      .catch((error) => console.log("error", error));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setButtonLoading(true);

    let userId = null;
    // const ip = await getIP();
    const formData = new FormData(document.getElementById(event.target.id));
    const name = formData.get("name");
    const telegram = formData.get("telegram");
    const site = formData.get("site");
    const domain = formData.get("domain");
    const subdomain = formData.get("subdomain");
    const productId = formData.get("productId");
    const officeId = formData.get("officeId");
    const comment = formData.get("comment");
    const createdAt = new Date().toISOString();

    const domainRegex = /^(?!https?:\/\/)[^\/\s]+\.([^\/\s]+)$/;
    const subdomainRegex = /^[a-zA-Z\d](?!.*--)[a-zA-Z\d-]*$/;

    if (!domainRegex.test(domain)) {
      toast.error("Неправильний формат домену");
      setButtonLoading(false);
      return;
    }

    let errorMessages = `Ви не заповнили обов'язкові поля: `;
    let hasError = false;

    if (!name) {
      errorMessages += `Ім'я `;
      hasError = true;
    }

    if (!telegram) {
      errorMessages += `Телеграм `;
      hasError = true;
    }

    if (!site) {
      errorMessages += `Посилання на сайт `;
      hasError = true;
    }
    if (!domain) {
      errorMessages += `Домен `;
      hasError = true;
    }
    if (!subdomain) {
      errorMessages += `Субдомен `;
      hasError = true;
    }

    // if (!productId) {
    //   errorMessages += `ID товару `;
    //   hasError = true;
    // }

    // if (!officeId) {
    //   errorMessages += `ID відділу `;
    //   hasError = true;
    // }

    if (!subdomainRegex.test(subdomain)) {
      toast.error(
        "Неправильний формат субдомену. Субдомен може містити лише англійські букви, дефіс та цифри"
      );
      setButtonLoading(false);
      return;
    }

    if (hasError) {
      toast.error(errorMessages, {
        position: "top-center",
      });
      setButtonLoading(false);
      return;
    }

    const createDomainData = await createDomain(domain, subdomain);
    // return;
    // const isUserExists = await checkIfUserExists(telegram);
    // if (!isUserExists) {
    //   const newUser = await createNewUser({
    //     name,
    //     telegram,
    //     ip,
    //   });
    //   console.log("newUser", newUser.id);
    //   userId = newUser.id;
    // } else {
    //   userId = await getUserByTelegram(telegram);
    // }
    // add new order

    // const ordersRef = collection(firestore, "orders");
    let d = {
      name,
      userId,
      originaTelegram: telegram,
      telegram: telegram.toLowerCase(),
      site,
      domain,
      subdomain,
      productId,
      officeId,
      comment,
      createdAt,
      // ip,
    };

    try {
      // addDoc(ordersRef, d);
      createNewTask(d, createDomainData);
      setOpen(true);
      document.getElementById(event.target.id).reset();
      setButtonLoading(false);
    } catch (err) {
      setButtonLoading(false);
      const raw = JSON.stringify({
        name: site,
        description: `
        Домен: ${domain}
        Товар: ${productId}
        Відділення: ${officeId}
        Коментар: ${comment}
        UserId: ${userId}
        Дата: ${moment(createdAt).format("DD.MM.YYYY HH:mm")}
        `,
        status: "New",
      });
      await sendTelegramMessage(`Помилка при додаванні заявки ${raw}`);
      console.log(err);
    }
    setButtonLoading(false);
  };

  const showHelp = (type) => {
    setShowHelpModal(type);
  };
  const closeHelpModal = () => {
    setShowHelpModal(false);
  };
  return (
    <>
      <Container sx={{ mt: 2 }}>
        <Typography
          component="h1"
          variant="h4"
          style={{ textAlign: "center", marginBottom: "20px" }}
        >
          Заявка на створення сайту
        </Typography>
        <Typography color={"#000000a3"} mb={2}>
          *Якщо ви не впевнені, що саме слід вводити, натисніть на кнопку біля
          текстового поля (
          <IconButton color="primary" style={{}}>
            <InfoIcon />
          </IconButton>
          ), щоб отримати додаткову інформацію. Якщо у вас все ще залишились
          запитання - відвідайте сторінку{" "}
          <Link to="/qa">Поширені запитання</Link>
        </Typography>
        {/* <Typography color={"#000000a3"} mb={2}>
          Зверніть увагу, з 21.10.2023 року змінились ціни.{" "}
          <Link to="/qa">Детальніше</Link>
        </Typography> */}

        <Grid
          container
          spacing={{
            md: 4,
            xs: 0,
          }}
        >
          <Grid xs={12}>
            <CssBaseline />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                // sx={{ mt: 3 }}
                id="order-form"
              >
                <Grid container spacing={2}>
                  <InputWrapper
                    name="name"
                    label="Ваше ім'я"
                    showHelp={showHelp}
                  />
                  <InputWrapper
                    name="telegram"
                    label="Нік в телеграм"
                    showHelp={showHelp}
                  />
                  <InputWrapper
                    name="site"
                    label="Приклад бажаного сайту (посилання)"
                    showHelp={showHelp}
                  />
                  <InputWrapper
                    name="domain"
                    label="Ваш домен (без http://)"
                    showHelp={showHelp}
                  />
                  <InputWrapper
                    name="subdomain"
                    label="Назва субдомену (одне-два слова англійською)"
                    showHelp={showHelp}
                  />
                  <InputWrapper
                    name="productId"
                    label="ID товару в CRM"
                    showHelp={showHelp}
                  />
                  <InputWrapper
                    name="officeId"
                    label="ID відділу в CRM"
                    showHelp={showHelp}
                  />

                  <Grid xs={12}>
                    <MinHeightTextarea
                      aria-label="minimum height"
                      minRows={3}
                      showTZText
                    />
                  </Grid>
                </Grid>
                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  loading={buttonLoading}
                >
                  <span>Відправити</span>
                </LoadingButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <Box sx={style}>
          <h2 id="unstyled-modal-title">Заявка додана успішно!</h2>
          <p id="unstyled-modal-description">
            ID вашої заявки - {clickUpTaskId} .Середній час очікування 5-48
            годин. Після завершення роботи над сайтом ви отримаєте повідомлення
            від{" "}
            <a href="https://t.me/easy_land_bot" target="_blank">
              @easy_land_bot
            </a>{" "}
          </p>
        </Box>
      </StyledModal>
      <HelpModal open={showHelpModal} handleClose={closeHelpModal} />
      <ToastContainer />
    </>
  );
}

const InputWrapper = ({ name, label, showHelp }) => {
  return (
    <Grid xs={12} display="flex" alignItems="center">
      <TextField name={name} required fullWidth id={name} label={label} />
      <IconButton
        color="primary"
        style={{ marginLeft: 5 }}
        onClick={() => showHelp(name)}
      >
        <InfoIcon />
      </IconButton>
    </Grid>
  );
};

const HelpModal = ({ open, handleClose }) => {
  const getText = () => {
    switch (open) {
      case "name":
        return "Ваше ім'я - потрібно для зручної комунікації";
      case "telegram":
        return "Нік в телеграм - це ім'я користувача, яке він вибирає сам. Якщо у вас не встановлений нік - <a target='_blank' href='https://telegra.ph/YAk-dodati-n%D1%96k-v-telegam-10-16'>скористайтесь інструкцією за посиланням </a>";
      case "site":
        return "Приклад бажаного сайту - це сайт, який вам подобається, або який ви хочете взяти за основу для вашого сайту";
      case "domain":
        return 'Ваш домен - це адреса для сайту, яку ви обрали самостійно (модуль 2, урок 1). Наприклад, "easy-land.site". Важливо щоб цей домен був оплачений (рахунок на оплату вам виставляли в кінці 3-го модуля) та написаний без помилок. Якщо у вас немає домена зверніться до свого ментора';
      case "subdomain":
        return "Субдомен - вам необхідно придумати і вказати самостійно, це може бути будь-яке слово англійською мовою, бажано, щоб воно асоціювалося з вашим товаром. Іншими словами, це частина адреси сайта, яка використовується для комфортного розміщення різних товарів на одному і тому ж домені. Наприклад: blender.easy-land.site - сайт з товаром блендер, ear.easy-land.site - сайт з товаром наушники";
      case "productId":
        return "ID товару - це унікальний номер певного товару в CRM. Дізнатися його можна за допомогою бота <a target='_blank' href='https://t.me/easy_land_manager_bot'>https://t.me/easy_land_manager_bot</a>";
      case "officeId":
        return "ID відділу - це унікальний номер, який ви отримали у повідомленні з доступом до CRM. Він залишається незмінним";
    }
  };
  return (
    <StyledModal
      open={Boolean(open)}
      onClose={handleClose}
      slots={{ backdrop: StyledBackdrop }}
    >
      <Box sx={style}>
        <Box display="flex" justifyContent="space-between">
          <h4 id="unstyled-modal-title">Допомога</h4>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <p
          id="unstyled-modal-description"
          style={{ wordBreak: "break-word" }}
          dangerouslySetInnerHTML={{ __html: getText() }}
        ></p>
      </Box>
    </StyledModal>
  );
};
